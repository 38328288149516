import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Button, FormControl, FormLabel } from "@mui/material";
import {
    buttonStyle,
  radioFormControlStyles,
  radioLabelStyles,
} from "../../features/onbordingWizard/constant";
import { RadioGroupMaterial, InputMaterial } from "../reuseable/formFields";
import { useFormikContext } from "formik";
import { selectWizardIsPlatformUser } from "../../features/onbordingWizard/OnboardingWizardSlice";

export type FormikType = {
  IsMultiLocation: string | boolean;
  NumberOfMultiLocations: string;
  DoLocationsOfferSameCapabilities: string | boolean;
  QualityManagementSystemType: string | boolean;
  HasSubsidiaries: string | boolean;
};
interface MoreInfoProps {
  name: string;
  onClickNext: (value: string) => void;
}
export const radios = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
export const QmsRadios = [
  { label: "Global", value: "Global" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Localized", value: "Localized" },
];

export default function MoreInfo({ name, onClickNext }: MoreInfoProps) {
  const form = useFormikContext<FormikType>();
  const {isPlatformUser} = useSelector(selectWizardIsPlatformUser);

  useEffect(() => {
   if (!form.values.IsMultiLocation) {
    // form.setFieldValue('Locations', [])
   }
   if (form.values.DoLocationsOfferSameCapabilities === null) {
    form.setFieldValue('DoLocationsOfferSameCapabilities', false)
   }
   if (form.values.QualityManagementSystemType === null) {
    form.setFieldValue('QualityManagementSystemType', "Global")
   }
  }, [form])
  
  return (
    <div>
      <RadioGroupMaterial
        id={""}
        formName={"IsMultiLocation"}
        label='Does your company have other locations in addition to its headquarters?'
        value={ form.values.IsMultiLocation }
        values={radios}
        sx={radioFormControlStyles}
      />

      {form.values.IsMultiLocation && (
        <Box sx={{ margin: "10px 0px" }}>
          <FormControl fullWidth sx={{ flexDirection: "row", margin: "auto", "& .MuiFormLabel-root":{
          padding: "1em 0 1em 1em"
        } }}>
            <FormLabel
              id='capabilities-radio-buttons-group-label'
              sx={{ ...radioLabelStyles, width: "100%" }}
            >
             Please specify the number of locations that you operate in addition to primary location*
            </FormLabel>
            <InputMaterial
              id={"NumberOfMultiLocations"}
              label={""}
              name={"NumberOfMultiLocations"}
              type='number'
              value={form.values.NumberOfMultiLocations}
              error={form.errors.NumberOfMultiLocations}
              touched={form.touched.NumberOfMultiLocations}
              sx={{
                maxWidth: "140px",
                alignSelf: "end",
                paddingRight: "1em"
              }}
            />
          </FormControl>
          <RadioGroupMaterial
            id={""}
            formName={"DoLocationsOfferSameCapabilities"}
            label='Does each location offer the same capabilities / services?'
            value={form.values.DoLocationsOfferSameCapabilities}
            values={radios}
            sx={{
              ...radioFormControlStyles,
              marginBottom: 0,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          />
          <RadioGroupMaterial
            id={""}
            formName={"QualityManagementSystemType"}
            label='Please specify how your Quality Management System applies across your locations?'
            value={form.values.QualityManagementSystemType === null ? 'Global' : form.values.QualityManagementSystemType}
            values={QmsRadios}
            sx={{
              ...radioFormControlStyles,
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          />
        </Box>
      )}

      {/* <RadioGroupMaterial
        id={""}
        formName={"HasSubsidiaries"}
        label='Do you operate subsidiaries (other entities)?'
        value={form.values.HasSubsidiaries}
        values={radios}
        sx={radioFormControlStyles}
      /> */}

      <Box component={"div"} sx={{ textAlign: "end" }}>
        <Button
          variant='contained'
          disabled={Boolean(form.errors?.NumberOfMultiLocations) || Boolean(isPlatformUser)}
          onClick={() => form.values.IsMultiLocation ? onClickNext('/wizard/locations') : form.handleSubmit()}
          sx={{ ...buttonStyle }}
        >
          {form.values.IsMultiLocation ? "Next" : "Submit"}
        </Button>
      </Box>
    </div>
  );
}
