import React from "react";
import { FormControl, InputLabel, TextField } from "@mui/material";
import { useFormikContext } from "formik";

export interface InputMaterialProps {
  id: string;
  label: string;
  placeholder?: string;
  type?: string;
  name: string;
  value: string;
  error: string | undefined;
  touched: any;
  disabled?: boolean;
  sx?: any
}
export interface FieldType {
  [key: string]: string;
}

export const materialInputStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".Mui-error.MuiInputBase-root": {
    border: "1px solid #ff1744",
  },
  ".MuiInputBase-root": {
    background: "rgba(252, 251, 254, 0.8)",
    border: "1px solid #C0C5D1",
    borderRadius: "6px",
    height: "3.2rem",
  },
  "& .MuiInputBase-root:hover": {
    border: "1px solid #1565D8",
    boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
  },
  ".Mui-focused.MuiInputBase-root": {
    border: "1px solid #1565D8",
    boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
  },
  ".Mui-disabled.MuiInputBase-root" : {
    border: "1px solid #C0C5D1",
    boxShadow: 'none',
    background: '#e8e9ed'
  },
  ".MuiFormHelperText-root": {
    // position: 'absolute',
    // bottom: '-22px',
    margin: 0,
    color: 'red'
  }
};
export default function InputMaterial({
  id,
  label,
  placeholder,
  type = "text",
  name,
  value,
  error,
  touched,
  disabled=false,
  sx={}
}: InputMaterialProps) {
  const { handleBlur, handleChange } = useFormikContext();

  return (
    <>
      <InputLabel
        sx={{ fontWeight: 700, color: touched && Boolean(error) ? "red" : "" }}
      >
        {label}
      </InputLabel>
      <FormControl variant='standard' fullWidth>
        <TextField
          id={id}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched && error ? error : ""}
          error={touched && Boolean(error)}
          margin='dense'
          // variant='outlined'
          fullWidth
          sx={{...materialInputStyles,...sx}}
        />
      </FormControl>
    </>
  );
}
