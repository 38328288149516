import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { FormikProps, FormikValues } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { selectOnboardingErrors, setOnboardingErrors } from "../../features/onboardingPortal/onboardingSlice";
import DatePickerMaterial from "../reuseable/formFields/DatePickerMaterial";
import InputMaterial from "../reuseable/formFields/InputMaterial";
import RadioGroupMaterial from "../reuseable/formFields/RadioGroupMaterial";
import SelectField from "../reuseable/formFields/SelectField";
import StepHeader from "./StepHeader";
import MultiValueInput from "../reuseable/formFields/MultiValueInput";
import { useGetCertificationsQuery, useGetClassificationsQuery } from "../../features/onboardingPortal/onboardingPortalApiSlice";
import SingleSelect from "../reuseable/formFields/SingleSelect";

type FormProps = {
  form: FormikProps<FormikValues>;
  name: string;
};

type Certification = {
  CertificationName: string;
  Description: string;
};

type Classification = {
  ClassificationName: string;
};

export default function Step2({ form: formikForm, name }: FormProps) {
  const dispatch = useDispatch();
  const { data: classification = [] } = useGetClassificationsQuery({});
  const { data: certifications = [] } = useGetCertificationsQuery({});
  const formErrors = useSelector(selectOnboardingErrors);
    
  useEffect(() => {
    let dataToStore = {
      name: name,
      isValid: formikForm.isValid,
      status: true,
      errors: formikForm.errors[name] ? formikForm.errors[name] : {},
      touched: formikForm.touched[name],
    };
    dispatch(setOnboardingErrors(dataToStore));
  }, [dispatch, formikForm.errors, formikForm.isValid, formikForm.status, formikForm.touched, name]);

  const sortedCertifications = certifications.data
    ? [...certifications.data].sort((a: Certification, b: Certification) =>
        a.Description.localeCompare(b.Description)
      ).map(({ CertificationName, Description }) => ({ title: Description, value: CertificationName }))
    : [];
  
  const sortedClassifications = classification.data
    ? [...classification.data].sort((a: Classification, b: Classification) =>
        a.ClassificationName.localeCompare(b.ClassificationName)
      ).map(({ ClassificationName }) => ({ title: ClassificationName, value: ClassificationName }))
    : [];

  return (
    <Grid container>
      <Grid item p={"10px 25px"} xs={12}>
        <StepHeader
          title={"General Company Information"}
          subTitle={
            "Please tell us more about your company including certifications and any diversity classification."
          }
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} sx={{ display: "flex", minHeight: '84px' }}>
        <RadioGroupMaterial
          id={"IsPublic"}
          formName={"step2"}
          value={formikForm.values.step2.IsPublic}
        />
        <Box component={"span"} sx={{ maxWidth: "150px" }}>
          {formikForm.values.step2.IsPublic && (
            <InputMaterial
              id={"Ticker"}
              label={""}
              name={`${name}.Ticker`}
              placeholder={"Ticker Symbol"}
              value={formikForm.values.step2.Ticker}
              error={formErrors.step2?.errors?.Ticker}
              touched={formErrors.step2?.touched?.Ticker}
            />
          )}
        </Box>
      </Grid>
      <Grid
        item
        p={"10px 25px"}
        xs={12}
        md={6}
        sx={{ "& .MuiInputBase-root": { height: "51px" } }}
      >
        <DatePickerMaterial
          id={"YearFounded"}
          label={"Year Founded"}
          formName={"step2"}
          fieldValue={formikForm.values.step2.YearFounded}
          error={formErrors.step2?.errors?.YearFounded}
          touched={formErrors.step2?.touched?.YearFounded}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12} md={6}>
        <SingleSelect
          id={"NumberOfEmployees"}
          formName={name}
          type={"single"}
          label={"Number of Employees"}
          placeholder={"Select"}
          options={['1-50', '51-100', '101-500', '501-1000', '1000+']}
          selected={[formikForm.values[name].NumberOfEmployees]}
          error={formErrors.step2?.errors?.NumberOfEmployees}
          touched={formErrors.step2?.touched?.NumberOfEmployees}
        />
      </Grid>
      <Grid item p={"10px 25px 5px 25px"} xs={12}>
        <SelectField
          id={"Certifications"}
          formName={name}
          label={"Accreditation/ Certifications"}
          placeholder={"Select all Accreditation/ Certifications"}
          options={sortedCertifications}
          selected={formikForm.values[name].Certifications}
          error={formErrors.step2?.errors?.Certifications}
          touched={formErrors.step2?.touched?.Certifications}
        />
      </Grid>
      <Grid item p={"5px 25px 10px 25px"} xs={12}>
        <MultiValueInput
          label={"Other Accreditation/ Certifications"}
          placeholder={"Type and press `Enter` or `Tab`"}
          options={formikForm.values.step2.OtherCertifications}
          error={formErrors.errors?.step2?.OtherCertifications}
          touched={formErrors.step2?.touched?.OtherCertifications}
          id={"OtherCertifications"}
          formName={name}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12}>
        <SelectField
          id={"Classifications"}
          formName={name}
          type={"multiple"}
          label={"Diversity Classification*"}
          placeholder={"Select Classifications - Diversity"}
          options={sortedClassifications}
          selected={formikForm.values[name].Classifications}
          error={formErrors.step2?.errors?.Classifications}
          touched={formErrors.step2?.touched?.Classifications}
        />
      </Grid>
    </Grid>
  );
}
