const config = [
  {
    name: "scenario1",
    location: false,
    subsidiaries: false,
    sameCapabilities: false,
  },
  {
    name: "scenario2",
    location: true,
    subsidiaries: false,
    sameCapabilities: true,
  },
  {
    name: "scenario3",
    location: true,
    subsidiaries: true,
    sameCapabilities: true,
  },
  {
    name: "scenario4",
    location: true,
    subsidiaries: false,
    sameCapabilities: false,
  },
  {
    name: "scenario5",
    location: true,
    subsidiaries: false,
    sameCapabilities: true,
  },
];

export const initialDefaultValues = {
  IsMultiLocation: false,
  NumberOfMultiLocations: 0,
  DoLocationsOfferSameCapabilities: false,
  QualityManagementSystemType: "Global",
  HasSubsidiaries: false,
  Locations: [
    // {
    //   LocationName: "KHI",
    //   AddressLine1: "street 1",
    //   AddressLine2: "street 12",
    //   PostalCode: "560061",
    //   IsActive: true,
    //   City: "Bengaluru",
    //   ShortCityName: "",
    //   State: "KR",
    //   Country: "India",
    //   GeographicRegionsServed: ["Western Europe"],
    //   Capabilities: [],
    //   ProviderName: "",
    //   QMSType: "",
    //   QMS: "",
    // },
  ],
};

const headerStyle = {
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1.9rem",
  lineHeight: "2.4rem",
  textAlign: "center",
  marginBottom: "1em",
};

const radioFormControlStyles = {
  display: "flex",
  flexDirection: "row",
  //   minHeight: "124px",
  "&:hover": {
    backgroundColor: "rgba(252, 251, 254, 0.8)",
  },
  //   "&:focus": {
  //     backgroundColor: "rgba(252, 251, 254, 0.8)",
  //   },
  "& .MuiFormLabel-root": { padding: "1em" },
  "& .MuiFormGroup-root": { width: "40%", justifyContent: "end" },
  borderRadius: "6px",
  marginBottom: "25px",
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
};

const radioLabelStyles = {
  width: "60%",
  margin: "auto",
  padding: "1em",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  alignSelf: "center",
};
const buttonStyle = {
    minWidth: "150px",
    height: "44px",
    margin: "20px 15px 30px 15px",
    textTransform: "none",
  };

export { config, headerStyle, radioFormControlStyles, radioLabelStyles, buttonStyle };
