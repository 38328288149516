import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getState } from "./authSlice";

const RequireAuth = () => {
  const {user,token} = useSelector(getState);
  const location = useLocation();

if (!token) {    
  return <Navigate to='/signin' state={{ from: location }} replace />
}

  return user === "Portal" ? (
    <Outlet />
  ) : (
    <Navigate to='/wizard/submit' state={{ from: location }} replace />
  );
};
export default RequireAuth;
