import React from "react";
import { Box, Backdrop, CircularProgress } from "@mui/material";

interface AppLoaderProps {
  open: boolean;
  message?: JSX.Element;
}
export default function AppLoader({ open, message }: AppLoaderProps) {
  return (
    <Box component='div'>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: { zIndex: { drawer: number } }) =>
            theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <Box component={"div"}  sx={{display: 'grid'}}>
          <CircularProgress color='inherit' sx={{margin: 'auto'}} data-testid="CircularProgress" />
         <Box sx={{marginTop: '1em'}}>
         {message}
         </Box>
        </Box>
        {/* <Box component={"div"}>{message}</Box> */}
      </Backdrop>
    </Box>
  );
}
