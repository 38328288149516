import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { getState, logOut } from "../../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useLogoutMutation } from "../../../features/auth/authApiSlice";
import { apiSlice } from "../../../app/api/apiSlice";
interface SidebarProps {
  logo: string;
  title: string;
  subTitle?: string[];
  backgroundImage?: string;
}

export default function SideBar({
  logo,
  title,
  subTitle = [],
  backgroundImage,
}: SidebarProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(getState);
  const isTabScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isPCScreen = useMediaQuery(theme.breakpoints.up("md"));
  const [logout] = useLogoutMutation();

  const handleLogout = () => {
    logout({})
      .unwrap()
      .then((payload) => {
        if (payload.data) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          dispatch(apiSlice.util.resetApiState());
          dispatch(logOut());
          navigate("/signin");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card
      sx={{
        maxWidth: isPCScreen ? "30%" : isTabScreen ? "35%" : "100%",
        width: "100%",
        height: isTabScreen ? "100vh" : "100%",
        // height: "100%",
        borderRight: "1px solid #ddd",
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          position: "inherit",
          padding: isPCScreen ? "10%" : isTabScreen ? "5%" : "7%",
          paddingBottom: "1%",
          paddingTop: "5%",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            paddingTop: "0.1em",
          }}
        >
          <img src={logo} alt='diligent logo' />
        </Box>
        <Box component={"div"}>
          <Typography
            variant='h5'
            sx={{
              marginTop: subTitle.length > 1 ? "10%" : "5%",
              fontWeight: 600,
              fontSize: "1em",
              lineHeight: "1.1em",
              alignItems: "center",
            }}
          >
            {title}
          </Typography>
          {subTitle.map((ele, index) => (
            <Typography
              variant='subtitle1'
              key={`subtitle-${index}`}
              sx={{
                marginTop: "1.8em",
                fontWeight: 400,
                fontSize: "0.8em",
                lineHeight: "1em",
              }}
            >
              {ele}
            </Typography>
          ))}
        </Box>
      </CardContent>
      <CardMedia
        component='img'
        // height="140"
        image={backgroundImage}
        alt='sidebar background'
        sx={{
          position: isTabScreen || isPCScreen ? "absolute" : "unset",
          bottom: "10px",
          maxWidth: isTabScreen || isPCScreen ? "80%" : '100%',
          left: isTabScreen || isPCScreen ? "50%" : 0,
          marginLeft: isTabScreen || isPCScreen ? "-40%" : 0,
        }}
      />
      {token && (
        <Box
          sx={{
            paddingLeft: isPCScreen ? "29px" : isTabScreen ? "14px" : "24px",
            textAlign: "start",
            position: "absolute",
            bottom: "1em",
          }}
        >
          <Button
            variant='contained'
            onClick={() => handleLogout()}
            sx={{
              textTransform: "none",
              borderRadius: "6px",
              height: "2rem",
              fontSize: "1rem",
            }}
          >
            Logout
          </Button>
        </Box>
      )}
    </Card>
  );
}
