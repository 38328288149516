import { createSlice } from "@reduxjs/toolkit";

const onboardingWizardSlice = createSlice({
  name: "wizard",
  initialState: { 
    wizard: {},
    isPlatformUser: false
   },
  reducers: {
    setOnboardingWizardErrors: (state, action) => {
      const { name } = action.payload;
      // @ts-ignore:next-line
      state[name] = { ...action.payload }
    },
    setOnboardingWizardStatus: (state, action) => {
      state.isPlatformUser = true
    },
  },
});

export const { setOnboardingWizardErrors, setOnboardingWizardStatus } = onboardingWizardSlice.actions;

export default onboardingWizardSlice.reducer;

export const selectWizardErrors = (state: { wizard: any; }) => state.wizard;
export const selectWizardIsPlatformUser = (state: { wizard: any, isPlatformUser: boolean }) => state.wizard;
