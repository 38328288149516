import { apiSlice } from "../../app/api/apiSlice";

export const onboardingPortalApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return {
      getCertifications: builder.query<any, {}>({
        query: () => ({
          url: "common/certification",
          method: "GET",
          mode: "cors",
        }),
      }),
      getClassifications: builder.query<any, {}>({
        query: () => ({
          url: "common/classification",
          method: "GET",
          mode: "cors",
        }),
      }),
      getGeographicregions: builder.query<any, {}>({
        query: () => ({
          url: "common/geographicregions",
          method: "GET",
          mode: "cors",
        }),
      }),
      getTherapeuticAreas: builder.query<any, {}>({
        query: () => ({
          url: "common/therapeuticareas",
          method: "GET",
          mode: "cors",
        }),
      }),
      getIndications: builder.query<any, {}>({
        query: () => ({
          url: "common/indications",
          method: "GET",
          mode: "cors",
        }),
      }),
      getCapability: builder.query<any, {}>({
        query: () => ({
          url: "common/capability",
          method: "GET",
          mode: "cors",
        }),
      }),
      getCountries: builder.query<any, {}>({
        query: () => ({
          url: "common/country",
          method: "GET",
          mode: "cors",
        }),
      }),
      getServiceCompliance: builder.query<any, {}>({
        query: () => ({
          url: "common/servicecompliancestatuses",
          method: "GET",
          mode: "cors",
        }),
      }),
      getProviderData: builder.query<any,{}>({
        query: (credentials) => ({
          url: "provider",
          method: "GET",
          mode: "cors",
        })
      }),
      saveStep: builder.mutation({
        query: (credentials) => ({
          url: "provider/save",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      submitProviderForm: builder.mutation({
        query: (credentials) => ({
          url: "provider/submit",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
    };
  },
});

export const {
  useGetCertificationsQuery,
  useGetClassificationsQuery,
  useGetGeographicregionsQuery,
  useGetTherapeuticAreasQuery,
  useGetIndicationsQuery,
  useGetCapabilityQuery,
  useGetCountriesQuery,
  useGetServiceComplianceQuery,
  useGetProviderDataQuery,
  useSaveStepMutation,
  useSubmitProviderFormMutation
} = onboardingPortalApiSlice;
