import React from "react";
import { Box, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import SideBar from "../sideBar";
import diligent_logo from "../../../assets/diligent_logo.svg";

interface LayoutProps {
  logo?: string;
  title: string;
  subTitle?: string[];
  backgroundImage?: string;
  children: React.ReactNode;
}
const Layout = ({
  logo = diligent_logo,
  title,
  subTitle = [],
  backgroundImage,
  children,
}: LayoutProps) => {
  const theme = useTheme();
  const isTabScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ display: !isTabScreen ? "flex" : "grid" }}>
      <SideBar
        logo={logo}
        title={title}
        subTitle={subTitle}
        backgroundImage={backgroundImage}
      />
      <Box
        sx={{
          background: "#E5E5E5",
          width: "100%",
          height: !isTabScreen ? "100vh" : "100%",
          display: "flex",
          alignItems: "center",
          overflowY: !isTabScreen ? "scroll" : "revert",
          padding: !isTabScreen ? "1rem 1.5rem" : 0,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Layout;
