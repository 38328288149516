import React, { useEffect, useState } from "react";
import { FormikValues, useFormikContext } from "formik";
import MaterialTable from "../reuseable/materialTable";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { InputMaterial } from "../reuseable/formFields";
import { LocationType } from "../../features/onbordingWizard/OnboardingWizard";
import { buttonStyle } from "../../features/onbordingWizard/constant";
import {
  useGetCapabilityQuery,
  useGetCountriesQuery,
  useGetGeographicregionsQuery,
} from "../../features/onboardingPortal/onboardingPortalApiSlice";
import SelectField from "../reuseable/formFields/SelectField";
// import Subsidiaries from "./Subsidiaries";
// import SingleSelect from "../reuseable/formFields/SingleSelect";
import QmsDropdown from "./QmsDropdown";
import { useSelector } from "react-redux";
import { selectWizardIsPlatformUser } from "../../features/onbordingWizard/OnboardingWizardSlice";
import SingleSelect from "../reuseable/formFields/SingleSelect";

export type FormikType = {
  NumberOfMultiLocations: number;
  QualityManagementSystemType: string;
  Locations: Array<LocationType>;
};
interface LocationFormProps {
  name: string;
  onClickSave: (value: any) => void;
  onClickNext: (value: string) => void;
  onClickSubmit: (value: any) => void;
  extraCapabilityField: boolean;
  extraQmsField: boolean;
  hasSubsidiaries: boolean;
  locations: Array<LocationType>;
  position?: number;
}
const errorObj = {
  Address: "",
  Address2: "",
  City: "",
  State: "",
  Country: "",
  ZipCode: "",
  GeographicRegionsServed: [""],
  Capabilities: [""],
  QMS: "",
  NumberOfMultiSubsidiaries: "",
};
const touchedObj = {
  Address: false,
  Address2: false,
  City: false,
  State: false,
  Country: false,
  Zipcode: false,
  GeographicCoverage: false,
  NumberOfMultiSubsidiaries: false,
  Capabilities: false,
  QMS: false,
};

function sortCountries(arr:any) {
  return arr.slice().sort((a: { Country: string; }, b: { Country: string; }) => {
    let fa = a.Country.toLowerCase(),
        fb = b.Country.toLowerCase();

    if (fa < fb) {
        return -1;
    }
    if (fa > fb) {
        return 1;
    }
    return 0;
})
}

export default function LocationForm({
  name,
  onClickSave,
  onClickNext,
  onClickSubmit,
  extraCapabilityField,
  extraQmsField,
  hasSubsidiaries,
  locations,
  position,
}: LocationFormProps) {
  const [currLocation, setCurrLocation] = useState(position ? position : 0);
  const form = useFormikContext<FormikType>();
  const {isPlatformUser} = useSelector(selectWizardIsPlatformUser);
  const [errors, setErrors] = useState<LocationType>();
  const [touched, setTouched] = useState<FormikValues>();
  const { data: geographicRegion = [] } = useGetGeographicregionsQuery({});
  const { data: capability = [] } = useGetCapabilityQuery({});
  const { data:countries = [] } = useGetCountriesQuery({});
  const [popOpen, setPopOpen] = useState(false);
  const [qmsError, setQmsError] = useState("")

  useEffect(() => {
    const filtered = capability.data ? capability.data.filter((obj: { IsMandatory: any; RFIAliasName: string })=> obj.IsMandatory) : [];
    const filteredValues = filtered.reduce((acc: any,obj: { RFIAliasName: any; }) => {if(obj?.RFIAliasName) acc.push(obj.RFIAliasName); return acc},[]);
    const generatedArr = new Array(form.values.NumberOfMultiLocations + 1).fill(
      {
        Address: "",
        Address2: "",
        ZipCode: "",
        City: "",
        // ShortCityName: "",
        State: "",
        Country: "",
        GeographicRegionsServed: [],
        Capabilities: filteredValues.length ? [...new Set([...filteredValues])] : [],
        QMS: "",
      }
    );
    const filledArr = generatedArr.map((item, index) => {
      if (form.values.Locations[index]) {
        let newLocVal = {...form.values.Locations[index], Capabilities: [...new Set([...filteredValues,...form.values.Locations[index].Capabilities])]}
        let d = { ...item, ...newLocVal };
        delete d._id;
        return d;
      } else {
        return item;
      }
    });

    form.setFieldValue("Locations", filledArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capability.data]);

  useEffect(() => {
    setQmsError('');
    if (Array.isArray(form.errors.Locations)) {
      form.errors.Locations[currLocation]
        ? setErrors(form.errors.Locations[currLocation] as LocationType)
        : setErrors(errorObj);
    } else {
      setErrors(errorObj);
    }
    if (Array.isArray(form.touched.Locations)) {
      form.touched.Locations[currLocation]
        ? setTouched(form.touched.Locations[currLocation])
        : setTouched(touchedObj);
    } else {
      setTouched(touchedObj);
    }
    if (form.values.Locations?.length === 0) {
      onClickNext("/wizard/more-info");
    }
  }, [
    currLocation,
    form.errors.Locations,
    form.touched.Locations,
    form.values.Locations,
    onClickNext,
  ]);

  const handleConfirmSubmit = () => {
    onClickSubmit(form);
    setPopOpen(false);
  };

  return (
    <div>
      <Grid container key={`${currLocation}-Locations-container`}>
        {form.values.Locations.find((ele) => ele.Address || ele.City) && (
          <Grid item xs={12} p={"10px 25px"}>
            <MaterialTable
              columns={["Address", "City", "State", "Country"]}
              rows={form.values.Locations}
              currLocation={currLocation}
              setCurrLocation={setCurrLocation}
              errors={form.errors.Locations}
            />
          </Grid>
        )}
        <Grid item xs={12} p={"10px 25px"}>
          <Typography variant='h5' sx={{ fontWeight: 600, fontSize: "1.2em" }}>
            {currLocation === 0
              ? `Primary Location`
              : `Location ${currLocation + 1}`}
          </Typography>
        </Grid>
        <Grid item p={"10px 25px"} xs={12} md={6}>
          <InputMaterial
            id={"Address"}
            placeholder={"North Street"}
            label={"Address 1*"}
            name={`Locations.[${currLocation}].Address`}
            value={form.values?.Locations[currLocation]?.Address}
            error={errors?.Address ? errors.Address : ""}
            touched={Boolean(touched?.Address)}
          />
        </Grid>
        <Grid item p={"10px 25px"} xs={12} md={6}>
          <InputMaterial
            id={"Address2"}
            placeholder={"Address 2"}
            label={"Address 2"}
            name={`Locations.[${currLocation}].Address2`}
            value={form.values?.Locations[currLocation]?.Address2}
            error={errors?.Address2 ? errors.Address2 : ""}
            touched={Boolean(touched?.Address2)}
          />
        </Grid>
        <Grid item p={"10px 25px"} xs={12} md={6}>
          <InputMaterial
            id={"City"}
            placeholder={"City"}
            label={"City*"}
            name={`Locations.[${currLocation}].City`}
            value={form.values?.Locations[currLocation]?.City}
            error={errors?.City ? errors.City : ""}
            touched={Boolean(touched?.City)}
          />
        </Grid>
        <Grid item p={"10px 25px"} xs={12} md={6}>
          <InputMaterial
            id={"State"}
            placeholder={"State"}
            label={"State/Province"}
            name={`Locations.[${currLocation}].State`}
            value={form.values?.Locations[currLocation]?.State}
            error={errors?.State ? errors.State : ""}
            touched={Boolean(touched?.State)}
          />
        </Grid>
        <Grid item p={"10px 25px"} xs={12} md={6}>
        <SingleSelect
          id={"Country"}
          formName={`Locations.[${currLocation}]`}
          type={"single"}
          label={"Country*"}
          placeholder={"Select Country"}
          options={
            countries.data
              ? sortCountries(countries.data).reduce(
                  (result: Array<string>, current: { Country: string }) => {
                    return current.Country
                      ? [...result, current.Country]
                      : result;
                  },
                  []
                )
              : []
          }
          selected={[form.values?.Locations[currLocation]?.Country ? form.values.Locations[currLocation].Country : ""]}
          error={errors?.Country ? errors.Country : ""}
          touched={Boolean(touched?.Country)}
        />
        </Grid>
        <Grid item p={"10px 25px"} xs={12} md={6}>
          <InputMaterial
            id={"ZipCode"}
            placeholder={"Postal code"}
            label={"Postal Code*"}
            name={`Locations.[${currLocation}].ZipCode`}
            value={form.values?.Locations[currLocation]?.ZipCode}
            error={errors?.ZipCode ? errors.ZipCode : ""}
            touched={Boolean(touched?.ZipCode)}
          />
        </Grid>
        <Grid item p={"10px 25px"} xs={12}>
          <SelectField
            id={"GeographicRegionsServed"}
            formName={`Locations.[${currLocation}]`}
            label={"Geographic Regions Served*"}
            placeholder={"Select all regions served"}
            type='multiple'
            options={
              geographicRegion.data
                ? geographicRegion.data.reduce(
                    (
                      result: Array<string>,
                      current: { GeographicRegionName: string }
                    ) => {
                      return current.GeographicRegionName
                        ? [
                            ...result,
                            {
                              title: current.GeographicRegionName,
                              value: current.GeographicRegionName,
                            },
                          ]
                        : result;
                    },
                    []
                  )
                : []
            }
            selected={
              form.values?.Locations[currLocation]?.GeographicRegionsServed
            }
            error={
              errors?.GeographicRegionsServed
                ? errors.GeographicRegionsServed.toString()
                : ""
            }
            touched={Boolean(touched?.GeographicRegionsServed)}
          />
        </Grid>
        {extraCapabilityField && (
          <Grid item p={"10px 25px"} xs={12}>
            <SelectField
              label={"Capabilities / Services Provided*"}
              placeholder={"Select all capabilities / services provided"}
              formName={`Locations.[${currLocation}]`}
              id={"Capabilities"}
              options={
                capability.data
                  ? capability.data.reduce(
                      (
                        result: Array<string>,
                        current: {
                          RFIAliasName: string;
                          _id: string;
                          RFIName: string;
                          IsMandatory: boolean
                        }
                      ) => {
                        return current.RFIAliasName
                          ? [
                              ...result,
                              {
                                title: current.RFIAliasName,
                                value: current.RFIName,
                                id: current._id,
                                IsMandatory: current.IsMandatory
                              },
                            ]
                          : result;
                      },
                      []
                    )
                  : []
              }
              selected={form.values?.Locations[currLocation]?.Capabilities}
              error={errors?.Capabilities ? errors.Capabilities.toString() : ""}
              touched={Boolean(touched?.Capabilities)}
            />
          </Grid>
        )}

        <Grid item p={"10px 25px"} xs={12}>
          <QmsDropdown
            currLocation={currLocation}
            errors={errors}
            touched={touched}
            qualityManagementSystemType={
              form.values.QualityManagementSystemType
            }
            locations={form.values.Locations}
          />
        </Grid>

        {/* {hasSubsidiaries && (
          <Grid item p={"10px 25px"} xs={12}>
            <Typography
              variant='h6'
              sx={{ fontWeight: 600, fontSize: "1.2em" }}
            >
              Subsidiaries
            </Typography>
            <Subsidiaries
              currLocation={currLocation}
              form={form}
              errors={errors}
              touched={touched}
            />
          </Grid>
        )} */}

        <Grid item xs={12} sx={{ textAlign: "end", paddingTop: "20px", marginBottom: "3em" }}>
          <Typography variant="body1" sx={{textAlign: 'center', color: 'red'}}>{qmsError}</Typography>
          {currLocation === 0 ? (
            <Button
              variant='outlined'
              onClick={() => onClickNext("/wizard/more-info")}
              sx={{ ...buttonStyle, backgroundColor: "transparent" }}
            >
              {"Back"}
            </Button>
          ) : (
            <Button
              variant='outlined'
              onClick={() => setCurrLocation((prev) => prev - 1)}
              sx={{ ...buttonStyle, backgroundColor: "transparent" }}
            >
              {"Back"}
            </Button>
          )}
          <Button
            variant='outlined'
            disabled={Boolean(
              errors &&
                Object.values(errors).find((e) =>
                  Array.isArray(e) ? e[0] !== "" : e !== ""
                )
            ) || Boolean(isPlatformUser)}
            onClick={() => {form.validateForm().then((res: any)=>{
              const availableQms: Array<string> = form.values.Locations.map((loc,index)=> {
                return index <= currLocation ? loc.City : ''
              });
              if (form.values.QualityManagementSystemType === "Hybrid" && !availableQms.includes(form.values.Locations[currLocation].QMS)) {
               setQmsError('Invalid QMS value')
              } else {onClickSave(form);setQmsError('')}
            })}}
            sx={{
              ...buttonStyle,
              backgroundColor: "transparent",
              "&.Mui-disabled": { backgroundColor: "rgba(0, 0, 0, 0.12)" },
            }}
          >
            {"Save"}
          </Button>
          <Button
            variant='contained'
            disabled={Boolean(
              errors &&
                Object.values(errors).find((e) =>
                  Array.isArray(e) ? e[0] !== "" : e !== ""
                )
            ) || Boolean(isPlatformUser)}
            onClick={() => {form.validateForm().then((res: any)=>{
              const availableQms: Array<string> = form.values.Locations.map((loc,index)=> {
                return index <= currLocation ? loc.City : ''
              });
              if (form.values.QualityManagementSystemType === "Hybrid" && !availableQms.includes(form.values.Locations[currLocation].QMS)) {
               setQmsError('Invalid QMS value')
              } else {setPopOpen(true);setQmsError('')}
            })}}
            sx={{ ...buttonStyle }}
          >
            {"Submit"}
          </Button>
          {currLocation < form.values.Locations.length - 1 ? (
            <Button
              variant='contained'
              disabled={Boolean(
                errors &&
                  Object.values(errors).find((e) =>
                    Array.isArray(e) ? e[0] !== "" : e !== ""
                  )
              )}
              onClick={()=>{form.validateForm().then((res: any)=>{
                const availableQms: Array<string> = form.values.Locations.map((loc,index)=> {
                  return index <= currLocation ? loc.City : ''
                });
                if (form.values.QualityManagementSystemType === "Hybrid" && form.values.Locations[currLocation]?.City !== "" && form.values.Locations[currLocation]?.QMS && !availableQms.includes(form.values.Locations[currLocation].QMS)) {
                setQmsError('Invalid QMS value')
                } else{ setQmsError('');onClickSave(form);setCurrLocation((prev) => prev + 1)}
              })}}
              sx={{ ...buttonStyle }}
            >
              {"Next Location"}
            </Button>
          ) : (
            <Button
              variant='contained'
              disabled={!form.isValid}
              onClick={()=>{form.validateForm().then((res: any)=>{
                const availableQms: Array<string> = form.values.Locations.map((loc,index)=> {
                  return index <= currLocation ? loc.City : ''
                });
                if (form.values.QualityManagementSystemType === "Hybrid" && form.values.Locations[currLocation]?.City !== "" && form.values.Locations[currLocation]?.QMS && !availableQms.includes(form.values.Locations[currLocation].QMS)) {
                setQmsError('Invalid QMS value')
                } else{ setQmsError('');onClickSave(form);onClickNext("/wizard/locations-confirmation")}
              })}}
              sx={{ ...buttonStyle }}
            >
              {"Confirm Locations"}
            </Button>
          )}
        </Grid>

        <Dialog
          sx={{ "& .MuiDialog-paper": { width: "80%" } }}
          maxWidth='xs'
          open={popOpen}
        >
          <DialogTitle>Confirm Submit</DialogTitle>
          <DialogContent dividers>Please confirm your location details and click yes</DialogContent>
          <DialogActions sx={{justifyContent: 'center'}}>
            <Button
              autoFocus
              onClick={() => setPopOpen(false)}
              variant='outlined'
              sx={{ ...buttonStyle }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleConfirmSubmit()}
              variant='contained'
              sx={{ ...buttonStyle }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
}
