import { Button, FormControl } from "@mui/material";
import React from "react";

interface ButtonFieldProps {
  text: string;
  type?: "button" | "submit";
  size?: "small" | "medium" | "large";
  disabled?: boolean
}
export default function ButtonField({
  text,
  type,
  size = "small",
  disabled = false
}: ButtonFieldProps) {
  return (
    <FormControl variant='standard' fullWidth>
      <Button
        variant='contained'
        size={size}
        type={type}
        sx={{ textTransform: "none", borderRadius: "6px", height: "3.2rem", fontSize: "1.2rem" }}
        disabled={disabled}
      >
        {text}
      </Button>
    </FormControl>
  );
}
