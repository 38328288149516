export const authCardStyles = {
  maxWidth: '750px',
  minWidth: '80%',
  margin: 'auto',
  // position: 'absolute',
  // left: '50%',
  // top: '50%',
  // transform: 'translate(-50%, -50%)',
  borderRadius: '20px',
}

export const authHeaderStyle = {
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '34px',
  lineHeight: '34px',
  textAlign: 'center',
  marginBottom: '24px'
}
