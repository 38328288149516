import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.png";
import Layout from "../../components/reuseable/layout/Layout";
// import { authCardStyles } from "./constant";
import { parseQueryParams } from "./ResetPassword";
import {
  useRegenerateVerificationMutation,
  useVerifyMutation,
} from "./authApiSlice";

export default function VerifyPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [regenerateStatus, setregenerateStatus] = useState(false);
  const [message, setMessage] = useState("Please wait...");
  const [errMessage, setErrMessage] = useState("");
  const [succMessage, setSuccMessage] = useState("");

  const params = parseQueryParams(
    searchParams.has("key") ? searchParams.get("key") : ""
  );
  const code = params?.vcode ? params.vcode : "";
  const email = params?.email ? params.email : "";

  const [verify] = useVerifyMutation();
  const [regenerate] = useRegenerateVerificationMutation();

  useEffect(() => {
    setregenerateStatus(false);
    setMessage("Account is being verified. Please wait...");
    setErrMessage("");
    setSuccMessage("");
    if (email && code) {
      verify({ Email: email, vcode: code })
        .unwrap()
        .then((payload) => {
          setSuccMessage(payload.message);
          setTimeout(() => {
            navigate("/signin");
          }, 3000);
        })
        .catch((err) => {
          console.log("Error: ", err);
          if (err.data.code === "VERIFY_TOKEN_EXPIRED") {
            setregenerateStatus(true);
            setErrMessage(err.data.message);
          } else {
            setErrMessage(err.data.message);
            setTimeout(() => {
              navigate("/signin");
            }, 5000);
          }
        });
    }
  }, [code, email, navigate, verify]);

  if (!code || !email) {
    // Validate from backend for correct data
    return <Navigate to='/' state={{ from: "/verify" }} replace />;
  }

  const handleRegenerate = () => {
    setregenerateStatus(false);
    setMessage("Please wait...");
    setErrMessage("");
    setSuccMessage("");
    regenerate({ email: email })
      .unwrap()
      .then((payload) => {
        setSuccMessage(payload.message);
        setTimeout(() => {
          navigate("/signin");
        }, 3000);
      })
      .catch((err) => {
        if (err.data.code === "RESEND_VERIFICATION_FAILED") {
          setregenerateStatus(true);
          setErrMessage(err.data.message);
        } else {
          setErrMessage(err.data.message);
          setTimeout(() => {
            navigate("/signin");
          }, 5000);
        }
      });
  };

  return (
    <Layout
      logo={diligent_logo}
      title={
        "Welcome to Diligent Qualification Platform Provider Onboarding Portal!"
      }
      subTitle={[
        "The Provider Onboarding Portal provides a simple process for submission of your company information for entry into the DQP Provider Community.",
      ]}
      backgroundImage={signup_sidebar_img}
      children={
        <Box sx={{ margin: "auto" }}>
          <Box component={"div"} sx={{ display: "grid" }}>
            {!Boolean(succMessage || errMessage || regenerateStatus) && (
              <CircularProgress color='inherit' sx={{ margin: "auto" }} />
            )}
            <Box
              sx={{
                marginTop: "1em",
                display: "flex",
                minWidth: "500px",
                minHeight: "100px",
                background: "#FFF",
                borderRadius: "10px",
              }}
            >
              {errMessage === "" && succMessage === "" ? (
                <Box sx={{ textAlign: "center", width: "100%" }}>
                  <Box
                    component='span'
                    sx={{
                      margin: "auto",
                      color: "#111",
                      textAlign: "center",
                      padding: "10px",
                      display: "grid",
                    }}
                  >
                    {message}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    textAlign: "center",
                    padding: "10px",
                    width: "100%",
                    display: "grid",
                  }}
                >
                  {succMessage && (
                    <Box
                      component='span'
                      sx={{
                        textAlign: "center",
                        color: "green",
                        margin: "auto",
                      }}
                    >
                      {succMessage}
                    </Box>
                  )}
                  {errMessage && (
                    <Box
                      component='span'
                      sx={{ textAlign: "center", color: "red", margin: "auto" }}
                    >
                      {errMessage}
                    </Box>
                  )}
                  {regenerateStatus && (
                    <Box component='span' sx={{ margin: "auto" }}>
                      <Button
                        variant='contained'
                        onClick={handleRegenerate}
                        sx={{ textTransform: "none", marginTop: "20px" }}
                      >
                        Regenerate link
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }
    />
  );
}
