// import React from "react";
import { FormikValues } from "formik";
import { Steps } from "../../types/onboarding";

export const generateSteps = (steps: Steps[]) => {
  if (steps.length < 1) {
    const mappedSteps = steps.map((step) => {
      const properties = Object.keys(step.values);
      let values = {};
      // @ts-ignore:next-line
      properties.map((prop) => (values[prop] = step.values[prop]));
      return { ...step, values: values };
    });
    return mappedSteps;
  } else {
    return steps;
  }
};

// function uppercaseFirstLetter(string: string) {
//   return string.charAt(0).toUpperCase() + string.slice(1);
// }

export const generateInitialValues = (
  filteredSteps: Steps[],
  data: FormikValues
) => {
  // These initial values are assumed to be `null`.
  // In more complex cases you may want to populate these values w/ data from an API, so you could do what you need to do in here
  const initialValues = filteredSteps.reduce((values, step) => {
    const properties = Object.keys(step.values);
    let stepValues = {};
    // @ts-ignore:next-line
    properties.map((prop) =>
      data[prop]
        ? (stepValues = {
            ...stepValues,
            [prop]: data[prop],
          })
        : (stepValues = { ...stepValues, [prop]: step.values[prop] })
    );
    
    return { ...values, [step.name]: stepValues };
  }, {});
  // console.log(initialValues)
  return initialValues;
};

export const getStepSchema = (currentIndex: number, steps: Steps[]) => {
  return steps[currentIndex].validationSchema;
};
