// @ts-nocheck
import { BaseQueryApi, createApi, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { setCredentials, logOut } from '../../features/auth/authSlice'

interface CustomErrorType {
  data: {
    message: string;
  }
}
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST + process.env.REACT_APP_API_PATH,
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    
    headers.set('Content-Type','application/json');
    // headers.set('Access-Control-Allow-Credentials', 'true')
    const token = getState().auth.token
    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }
    return headers
  }
})

const baseQueryWithReauth = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.data?.code === "AUTH_TOKEN_EXPIRED") {
    api.dispatch(logOut())
  }

  if (result?.error?.originalStatus === 403) {
    console.log('sending refresh token')
    // send refresh token to get new access token
    const refreshResult = await baseQuery('/refresh', api, extraOptions)
    console.log(refreshResult)
    if (refreshResult?.data) {
      const user = api.getState().auth.user
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }))
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logOut())
    }
  }

  return result
}

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth as BaseQueryFn<string | FetchArgs, unknown, CustomErrorType, {}>,
  endpoints: builder => ({})
})
