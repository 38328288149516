import * as yup from "yup";

export const wizardSchema = yup.object().shape({
  IsMultiLocation: yup.boolean(),
  NumberOfMultiLocations: yup.number().when("IsMultiLocation", {
    is: (IsMultiLocation: boolean) => IsMultiLocation,
    then: yup.number().min(1, "Invalid").required("Required"),
    otherwise: yup.number(),
  }),
  DoLocationsOfferSameCapabilities: yup.boolean(),
  QualityManagementSystemType: yup.string(),
  HasSubsidiaries: yup.boolean(),
  Locations: yup
    .array()
    .when("IsMultiLocation", {
      is: (IsMultiLocation: boolean) => IsMultiLocation,
      then: yup
        .array(
          yup.object().shape({
            Address: yup.string().required("Address1 required"),
            Address2: yup.string(),
            City: yup.string().required("City required"),
            State: yup.string(),
            Country: yup.string().required("Country required"),
            ZipCode: yup
              .string()
              .matches(/^[A-Za-z0-9]{3,10}$/g, "Not a valid postal code")
              .required("Postal Code Required"),
            GeographicRegionsServed: yup
              .array()
              .of(yup.string())
              .min(1, "Select atleast one"),
          })
        )
        .min(1, "Fill atleast one")
        .required("Required"),
      otherwise: yup.array(),
    })
    .when("DoLocationsOfferSameCapabilities", {
      is: (DoLocationsOfferSameCapabilities: boolean) =>
        !DoLocationsOfferSameCapabilities,
      then: yup.array(
        yup.object().shape({
          Capabilities: yup
            .array()
            .min(1, "Fill atleast one")
            .required("Required"),
        })
      ),
    })
    .when("QualityManagementSystemType", {
      is: (QualityManagementSystemType: string) =>
        QualityManagementSystemType === "Hybrid",
      then: yup.array(
        yup.object().shape({
          QMS: yup.string().required("Required"),
        })
      ),
    }),
});
