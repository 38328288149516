import React, { useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  ButtonField,
  InputMaterial,
  PasswordField,
} from "../../components/reuseable/formFields";
import diligent_logo from "../../assets/diligent_logo.svg";
import signup_sidebar_img from "../../assets/signup_sidebar.png";
import { Formik, Form } from "formik";
import {
  forgotPasswordValidationSchema,
  resetPasswordValidationSchema,
} from "./authSchema";
import Layout from "../../components/reuseable/layout/Layout";
import { authCardStyles, authHeaderStyle } from "./constant";
import AppSnackBar from "../../components/reuseable/appSnackBar";
import {
  useForgotPassordMutation,
  useResetPassordMutation,
} from "./authApiSlice";

export const parseQueryParams = (hex: string | null) => {
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  if (hex) {
    const decodedQueryStr = base64regex.test(hex) ? atob(hex) : "";
    const urlParams = new URLSearchParams(decodedQueryStr);
    return Object.fromEntries(urlParams);
  } else {
    return {};
  }
};

export default function ResetPasswordPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const params = parseQueryParams(
    searchParams.has("key") ? searchParams.get("key") : ""
  );
  const code = params?.vcode ? params.vcode : "";
  const initialValues = {
    email: params?.email ? params.email : "",
    password: "",
    confirmPassword: "",
  };

  const [forgotPassword] = useForgotPassordMutation();
  const [resetPassword] = useResetPassordMutation();

  return (
    <Layout
      logo={diligent_logo}
      title={
        "Welcome to Diligent Qualification Platform Provider Onboarding Portal!"
      }
      subTitle={[
        "The Provider Onboarding Portal provides a simple process for submission of your company information for entry into the DQP Provider Community.",
      ]}
      backgroundImage={signup_sidebar_img}
      children={
        <Card sx={{ ...authCardStyles, padding: "10%" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={
              !code
                ? forgotPasswordValidationSchema
                : resetPasswordValidationSchema
            }
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              setErrorMessage("");
              if (!code) {
                forgotPassword({ Email: values.email })
                  .unwrap()
                  .then((payload) => {
                    setSuccessMessage(payload.message);
                    setTimeout(() => {
                      navigate("/signin");
                    }, 5000);
                  })
                  .catch((err) => {
                    if (err.data) {
                      setErrorMessage(err.data.message);
                    } else {
                      setErrorMessage(err.error);
                    }
                  });
              } else {
                resetPassword({
                  Email: values.email,
                  vcode: code,
                  PasswordHash: values.password,
                })
                  .unwrap()
                  .then((payload) => {
                    setSuccessMessage(payload.message);
                    setTimeout(() => {
                      navigate("/signin");
                    }, 1000);
                  })
                  .catch((err) => {
                    if (err.data) {
                      setErrorMessage(err.data.message);
                    } else {
                      setErrorMessage(err.error);
                    }
                  });
              }

              setSubmitting(false);
            }}
          >
            {(form) => {
              return (
                <Form>
                  {" "}
                  {successMessage === "" ? (
                    <>
                      <Typography variant='h3' sx={authHeaderStyle}>
                        {!code ? "Reset password!" : "Create your password!"}
                      </Typography>
                      {!code ? (
                        <Box component={"div"} sx={{ marginBottom: "30px" }}>
                          <InputMaterial
                            id={"email"}
                            label={"Email*"}
                            placeholder={"Please enter email-id"}
                            name={"email"}
                            value={form.values.email}
                            error={form.errors.email as string}
                            touched={form.touched.email}
                          />
                        </Box>
                      ) : (
                        <>
                          <Box component={"div"} sx={{ marginBottom: "20px" }}>
                            <PasswordField
                              label={"New Password*"}
                              id={"password"}
                              placeholder={"New password"}
                            />
                          </Box>
                          <Box component={"div"} sx={{ marginBottom: "30px" }}>
                            <PasswordField
                              label={"Confirm Password*"}
                              id={"confirmPassword"}
                              placeholder={"Confirm your password"}
                            />
                          </Box>
                        </>
                      )}

                      <Box component={"div"} sx={{ marginBottom: "20px" }}>
                        <ButtonField
                          text={code ? "Submit Password" : "Submit Email"}
                          type={"submit"}
                          size='large'
                          disabled={form.isSubmitting}
                        />
                      </Box>
                    </>
                  ) : (
                    <Typography
                      variant='h6'
                      sx={{
                        marginBottom: "24px",
                        color: "green",
                        textAlign: "center",
                      }}
                    >
                      {successMessage}
                    </Typography>
                  )}
                  <Typography
                    variant='body1'
                    sx={{
                      textAlign: "center",
                      "& a": { textDecoration: "none" },
                    }}
                  >
                    Remember password? Click{" "}
                    <Link to={"/signin"}>
                      {" "}
                      <strong>here</strong>
                    </Link>{" "}
                    to Sign In.
                  </Typography>
                </Form>
              );
            }}
          </Formik>
          {errorMessage && (
            <AppSnackBar open={true} message={errorMessage} type={"error"} />
          )}
          {/* {successMessage && (
            <AppSnackBar
              open={true}
              message={successMessage}
              type={"success"}
            />
          )} */}
        </Card>
      }
    />
  );
}
