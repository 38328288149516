import React, { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getState } from "./authSlice";

const MainAuthOutlet = () => {
  const dispatch = useDispatch();
  const { user, token } = useSelector(getState);
  const location = useLocation();
  
  // For testing auth locally
  useEffect(() => {
    // dispatch(setCredentials({ user: "Portal", accessToken: "hsKJS86gjasFg" }));
  }, [dispatch]);

  // console.log(user, token);
  if (!token) {
    return <Outlet />;
  }
  return user !== "Portal" ? (
    <Navigate to='/wizard/submit' state={{ from: location }} replace />
  ) : (
    <Navigate to='/onboarding' state={{ from: location }} replace />
  );
};
export default MainAuthOutlet;
