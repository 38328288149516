import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, IconButton } from "@mui/material";
import { LocationType } from "../../../features/onbordingWizard/OnboardingWizard";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormikErrors } from "formik";

const tableRowStyle = {
  "& .MuiTableCell-root": {
    border: 0,
  },
};

interface MaterialTableProps {
  rows: Array<LocationType>;
  columns: Array<string>;
  currLocation: number;
  setCurrLocation: (val: number) => void;
  errors?: string | string[] | FormikErrors<LocationType>[] | undefined;
  actionBtn?: boolean
}

export default function MaterialTable({
  columns,
  rows,
  currLocation,
  setCurrLocation,
  errors,
  actionBtn = true
}: MaterialTableProps) {

  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 700 }} aria-label='locations table'>
        {rows.find(row=>(row.Address && row.State && row.Country && row.IsPrimary)) && <TableHead>
          <TableRow sx={{"& .MuiTableCell-root": {
              padding: '5px',
              fontWeight: 600
            }}}>
              {/* <TableCell align='left'>
                
              </TableCell> */}
            {columns.map((col) => (
              <TableCell align='left' key={col}>
                {col}
              </TableCell>
            ))}
            {actionBtn && <TableCell align='right' sx={{width: "30px"}}></TableCell>}
          </TableRow>
        </TableHead>}
        <TableBody>
          {rows.map((row, index) => ((row.Address || row.City)) ? (
            <TableRow key={`${row.Address}-row-${index}`} sx={{...tableRowStyle,"& .MuiTableCell-root": {
              padding: '5px', wordBreak: "break-word",
            }}}>
              {/* <TableCell component='th' scope='row' sx={{fontWeight: 700}}>
              {row.IsPrimary ? `(P)` : ``}
              </TableCell> */}
              <TableCell component='th' scope='row'>
                {`${row.IsPrimary ? `(P) ` : ``}${row.Address}`}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.City}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.State}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.Country}
              </TableCell>
                {actionBtn &&  <TableCell component='th' align='right' scope='row'>
                  <IconButton
                    aria-label='edit'
                    disabled={currLocation === index}
                    onClick={() => setCurrLocation(index)}
                    sx={{color: errors && Array.isArray(errors) && errors[index] && Object.values(errors[index]).find(i=>i!=="") ? 'red' : ''}}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </TableCell>}
            </TableRow>
          ) : "")}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
