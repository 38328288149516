import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#1A1D4E'
    },
    secondary: {
      main: '#19857b'
    },
    error: {
      main: red.A400
    }
  }
})

export default theme
