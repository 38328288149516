export const headerStyle = {
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "1.9rem",
  lineHeight: "1.9rem",
  textAlign: "center",
  marginBottom: "0.5em",
};

export const subHeaderStyle = {
  textAlign: "center",
  marginBottom: "1em",
  fontSize: '1.1em'
};
