import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: localStorage.getItem('user'), token: localStorage.getItem('token'),  status: localStorage.getItem('status')},
  reducers: {
    setCredentials: (state, action) => {
      const { user,accessToken,status } = action.payload;
      localStorage.setItem('user',user);
      localStorage.setItem('token',accessToken);
      localStorage.setItem('status',status);
      state.user = user
      state.token = accessToken
      state.status = status
    },
    setOnboardingStatus: (state, action) => {
      const { status } = action.payload;
     const newStatus =  (status === process.env.REACT_APP_USER_ONBOARD_APPROVED_STATUS) ? "Wizard" : "Portal";
     localStorage.setItem('user',newStatus);
     localStorage.setItem('status',status);
     state.user = newStatus
     state.status = status
    },
    logOut: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("status");
      state.user = null
      state.token = null
      state.status = null
    }
  }
})

export const { setCredentials, setOnboardingStatus, logOut } = authSlice.actions

export default authSlice.reducer

export const getState = (state: { auth: { user: any, token: any, status: any } }) => state.auth
export const selectCurrentUser = (state: { auth: { user: any } }) => state.auth.user
export const selectCurrentToken = (state: { auth: { token: any } }) => state.auth.token
