import * as yup from "yup";

function testUrl(url: string | undefined) {
  // eslint-disable-next-line no-useless-escape
  const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  if (url && url.match(urlRegex)) {
    if (url.length > 3 && url.substring(0, 4).toLowerCase() === "wwww")
      return false;
    return true;
  }
  return false;
}
export const signUpValidationSchema = yup.object().shape({
  firstname: yup
    .string()
    .required("Enter your first name."),
  lastname: yup
    .string()
    .required("Enter your last name."),
  email: yup
    .string()
    .email("Entered email must be a valid email")
    .required("Enter your Email."),
    providerName: yup
    .string()
    .required("Enter your Company Name."),
  companyUrl: yup
    .string()
    .required("Enter your Company URL."),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Password does not match")
    .required("Confirm your password"),
});

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Entered email must be a valid email")
    .required("Enter your Email."),
  password: yup.string().required("Please Enter your password"),
});

export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Entered email must be a valid email")
    .required("Enter your Email."),
});

export const resetPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Entered email must be a valid email")
    .required("Enter your Email."),
  password: yup
    .string()
    .required("Please Enter your password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
      "Must Contain 8 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Case Character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Password does not match")
    .required("Confirm your password"),
});

export const verifySchema = yup.object().shape({
  email: yup
    .string()
    .email("Entered email must be a valid email")
    .required("Enter your Email."),
  vcode: yup
    .string()
    .min(4, "Invalid code")
    .required("Please Enter your code")
    .min(4, "Invalid code"),
});
