import { createSlice } from "@reduxjs/toolkit";

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: { step1: null, step2: null, step3: null },
  reducers: {
    setOnboardingErrors: (state, action) => {
      const { name } = action.payload;
      // @ts-ignore:next-line
      state[name] = { ...action.payload }
    //   { ...state, [name]: { ...action.payload } };
    },
  },
});

export const { setOnboardingErrors } = onboardingSlice.actions;

export default onboardingSlice.reducer;

export const selectOnboardingErrors = (state: { onboarding: any }) => state.onboarding;
