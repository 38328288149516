import React from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import RequireAuth from "./features/auth/RequireAuth";
import Welcome from "./features/onbordingWizard/Welcome";
import OnboardingPortalPage from "./features/onboardingPortal";
import OnboardingWizardPage from "./features/onbordingWizard";
import SignIn from "./features/auth/SignIn";
import SignUp from "./features/auth/SignUp";
import RequireOnboarded from "./features/auth/RequireOnboarded";
import MainAuthOutlet from "./features/auth/MainAuthOutlet";
import VerifyPage from "./features/auth/Verify";
import ResetPasswordPage from "./features/auth/ResetPassword";
import SubmitConfirmation from "./components/onboardingSteps/SubmitConfirmation";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        {/* public routes */}
        <Route element={<MainAuthOutlet />}>
          <Route index element={<SignUp />} />
          <Route path='signin' element={<SignIn />} />
          <Route path='reset-password' element={<ResetPasswordPage />} />
          <Route path='verify' element={<VerifyPage />} />
        </Route>

        {/* portal protected routes */}
        <Route path='onboarding' element={<RequireAuth />}>
          <Route index element={<OnboardingPortalPage currentStepName={'step1'} />} />
          <Route path='step1' element={<OnboardingPortalPage currentStepName={'step1'} />} />
          <Route path='step2' element={<OnboardingPortalPage currentStepName={'step2'} />} />
          <Route path='step3' element={<OnboardingPortalPage currentStepName={'step3'} />} />
          <Route path='submit-confirmation' element={<SubmitConfirmation />} />
        </Route>

        {/* wizard protected routes */}
        <Route path='wizard' element={<RequireOnboarded />}>
          <Route index element={<Welcome />} />
          <Route index path='welcome' element={<Welcome />} />
          <Route path='more-info' element={<OnboardingWizardPage name={"more-info"} />} />
          <Route path='locations' element={<OnboardingWizardPage name={"locations"} />} />
          <Route path='locations-confirmation' element={<OnboardingWizardPage name={"locations-confirmation"} />} />
          <Route path='submit' element={<OnboardingWizardPage name={"submit"} />} />
        </Route>
        <Route path='*' element={<p>404 - Path not resolved</p>} />
      </Route>
    </Routes>
  );
}

export default App;
