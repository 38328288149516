import React, { useState } from "react";
import { FormikValues, useFormikContext } from "formik";
import { LocationType } from "../../features/onbordingWizard/OnboardingWizard";
import SingleSelect from "../reuseable/formFields/SingleSelect";
import { FormikType } from "./LocationForm";
import { InputMaterial } from "../reuseable/formFields";

interface QmsDropdownProps {
  currLocation: number;
  errors: LocationType | undefined;
  touched: FormikValues | undefined;
  qualityManagementSystemType: string;
  locations: Array<LocationType>;
}

function generateQMSDropdown(
  locations: Array<LocationType>,
  currIndex: number,
  QualityManagementSystemType: string
) {
  let availableQms: Array<string> = [];
  if (locations.length === 0) {
    return [];
  }
  if (QualityManagementSystemType === "Hybrid") {
    locations.map((curr, i) => {
      if (i === 0 && curr.QMS !== "") {
        availableQms.push(curr.City);
      } else if (
        !availableQms.includes(curr.City) &&
        curr.City === curr.QMS &&
        curr.QMS !== ""
      ) {
        availableQms.push(curr.City);
      } else {
        curr.City !== "" ?? availableQms.push(curr.City);
      }
      return curr;
    });
    availableQms.push(locations[currIndex].City);
    return availableQms.filter(
      (n, i) => availableQms.indexOf(n) === i && n !== ""
    );
  } else {
    if (QualityManagementSystemType === "Global") {
      return [locations[0].City];
    } else {
      return [locations[currIndex].City];
    }
  }
}

function selectedQms(
  locations: Array<LocationType>,
  currIndex: number,
  QualityManagementSystemType: string
) {
  return QualityManagementSystemType === "Hybrid"
    ? locations[currIndex]?.QMS
      ? [locations[currIndex].QMS]
      : ['']
    : QualityManagementSystemType === "Global"
    ? locations[0]?.QMS
      ? [locations[0].QMS]
      : []
    : locations[currIndex]?.City
    ? [locations[currIndex].City]
    : [""];
}

export default function QmsDropdown({
  currLocation,
  errors,
  touched,
  qualityManagementSystemType,
  locations,
}: QmsDropdownProps) {
    const form = useFormikContext<FormikType>();

  const [selected] = useState(
    selectedQms(locations, currLocation, qualityManagementSystemType)
  );

  return (
    <div>
      {qualityManagementSystemType === "Hybrid" ? (
        <SingleSelect
          id={"QMS"}
          formName={`Locations.[${currLocation}]`}
          type={"single"}
          label={"Governing Quality Management System Location*"}
          placeholder={"Select Governing Quality Management System Location"}
          options={generateQMSDropdown(locations, currLocation, qualityManagementSystemType)}
          selected={selected}
          error={errors?.QMS ? errors.QMS : ""}
          touched={Boolean(touched?.QMS)}
        />
      ) : (
        <InputMaterial
          id={"QMS"}
          placeholder={"Governing Quality Management System Location"}
          label={"Governing Quality Management System Location"}
          name={`Locations.[${currLocation}].QMS`}
          value={qualityManagementSystemType === "Localized" ? form.values.Locations[currLocation]?.City : selected[0] }
          error={errors?.QMS ? errors.QMS : ""}
          touched={Boolean(touched?.QMS)}
          disabled={currLocation === 0 || true}
        />
      )}
    </div>
  );
}
