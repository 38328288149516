import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { FormikProps, FormikValues, useFormikContext } from "formik";

export type ItemType = {
  label: string;
  value: string | boolean;
};
interface RadioGroupMaterialProps {
  id: string;
  formName: string;
  value: string | boolean;
  values?: Array<ItemType>;
  sx?: SxProps | any;
  label?: string;
}

const radioLabelStyles = {
  width: "60%",
  margin: "auto",
  paddingLeft: "1em",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
};

export default function RadioGroupMaterial({
  id,
  formName,
  value,
  values = [
    { label: "Private", value: false },
    { label: "Public", value: true },
  ],
  sx = {},
  label = "",
}: RadioGroupMaterialProps) {
  const form = useFormikContext<FormikProps<FormikValues>>();

  const formikFormName = id !== "" ? `${formName}.${id}` : `${formName}`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = e.target.value;
    let newValue: string | boolean = "";
    if (changedValue === "true" || changedValue === "false") {
      newValue = changedValue === "true" ? true : false;
    } else {
      newValue = changedValue;
    }
    // console.log({changedValue,formikFormName,newValue})
    form.setFieldValue(formikFormName, newValue);
    // setRadioValue(changedValue);
  };
  return (
    <FormControl sx={{ ...sx }}>
      <FormLabel
        id='subsidiaries-radio-buttons-group-label'
        sx={radioLabelStyles}
      >
        {label}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby='private-public-radio-buttons-group-label'
        name={id}
        value={value}
        onChange={handleChange}
        // sx={{ marginTop: 0, marginBottom: 0, height: "100%" }}
      >
        {/* <FormControlLabel value='private' control={<Radio />} label='Private' />
        <FormControlLabel value='public' control={<Radio />} label='Public' /> */}
        {values.map((item, index) => (
          <FormControlLabel
            key={`${id}-${index}`}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
