import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './api/apiSlice'
import authReducer from '../features/auth/authSlice'
import onboardingReducer from '../features/onboardingPortal/onboardingSlice'
import onboardingWizardReducer from '../features/onbordingWizard/OnboardingWizardSlice'

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    onboarding: onboardingReducer,
    wizard: onboardingWizardReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  // devTools: true
})
