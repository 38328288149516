import React, { useEffect, useState } from "react";
import { Grid, InputLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectOnboardingErrors, setOnboardingErrors } from "../../features/onboardingPortal/onboardingSlice";
import { FormProps } from "../../types/onboarding";
import InputMaterial from "../reuseable/formFields/InputMaterial";
import MultiValueInput from "../reuseable/formFields/MultiValueInput";
import SelectField from "../reuseable/formFields/SelectField";
import StepHeader from "./StepHeader";
import {
  useGetCapabilityQuery,
  useGetGeographicregionsQuery,
  useGetIndicationsQuery,
  useGetServiceComplianceQuery,
  useGetTherapeuticAreasQuery,
} from "../../features/onboardingPortal/onboardingPortalApiSlice";
import { useFormikContext } from "formik";

export default function Step3({ form, name, errors }: FormProps) {
  const dispatch = useDispatch();
  const formErrors = useSelector(selectOnboardingErrors);
  const { data: geographicRegion = [] } = useGetGeographicregionsQuery({});
  const { data: therapeuticAreas = [] } = useGetTherapeuticAreasQuery({});
  const { data: indications = [] } = useGetIndicationsQuery({});
  const { data: capability = [] } = useGetCapabilityQuery({});
  const { data: serviceCompliance = [] } = useGetServiceComplianceQuery({});

  interface Indication {
    TherapeuticAreaId: string;
    Indication: string; // Example, replace with actual properties
    _id: string; // Example, replace with actual properties
    // Add other properties as needed
  }

  interface FormValues {
    step3: {
      TherapeuticAreas: string[];
      Indications: string[];
    };
  }

  const { values } = useFormikContext<FormValues>(); // Get Formik context values

  const [filteredIndications, setFilteredIndications] = useState<Indication[]>([]);

  useEffect(() => {
    let dataToStore = {
      name: name,
      isValid: form.isValid,
      status: true,
      errors: form.errors[name] ? form.errors[name] : {},
      touched: form.touched[name],
    };
    dispatch(setOnboardingErrors(dataToStore));
  }, [dispatch, form.errors, form.isValid, form.status, form.touched, name]);

  useEffect(() => {
    if (capability.data) {
      const filtered = capability.data.filter((obj: { IsMandatory: any }) => obj.IsMandatory);
      const filteredValues = filtered.reduce((acc: any, obj: { RFIAliasName: any }) => {
        if (obj?.RFIAliasName) acc.push(obj.RFIAliasName);
        return acc;
      }, []);
      form.setFieldValue(`${name}.ProviderCapabilities`, [...new Set([...filteredValues, ...form.values[name].ProviderCapabilities])]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capability.data]);

  useEffect(() => {
    if (indications?.data) {

      const filterIndications = indications.data.filter((item: Indication) => {
        const therapeuticAreas = values?.step3?.TherapeuticAreas || [];

        const TherapeuticAreasLowerCaseAndTrim = therapeuticAreas.map((area: string) => area.trim().toLowerCase());
        const tId = item?.TherapeuticAreaId?.trim().toLowerCase();

        const isMatch = TherapeuticAreasLowerCaseAndTrim.includes(tId);

        return isMatch;
      });

      setFilteredIndications(filterIndications);
    } else {
      // Clear the state if no matching indications are found
      setFilteredIndications([]);
    }
  }, [values.step3.TherapeuticAreas, indications?.data]);

  const sortedTherapeuticAreas = therapeuticAreas.data
    ? [...therapeuticAreas.data].sort((a: { TherapeuticAreaName: string }, b: { TherapeuticAreaName: string }) =>
        a.TherapeuticAreaName.localeCompare(b.TherapeuticAreaName)
      )
    : [];

  const sortedGeographicRegions = geographicRegion.data
    ? [...geographicRegion.data].sort((a: { GeographicRegionName: string }, b: { GeographicRegionName: string }) =>
        a.GeographicRegionName.localeCompare(b.GeographicRegionName)
      )
    : [];

  const sortedCapabilities = capability.data
    ? [...capability.data].sort((a: { RFIAliasName: string }, b: { RFIAliasName: string }) =>
        a.RFIAliasName.localeCompare(b.RFIAliasName)
      )
    : [];

  const sortedServiceCompliance = serviceCompliance.data
    ? [...serviceCompliance.data].sort((a: { Description: string }, b: { Description: string }) =>
        a.Description.localeCompare(b.Description)
      )
    : [];
        
  return (
    <Grid container>
      <Grid item p={"10px 25px"} xs={12}>
        <StepHeader
          title={"Experience  & Services Offered"}
          subTitle={"Please enter information about areas served and capabilities provided."}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12}>
        <Grid container>
          <Grid item xs={7} sm={6} md={5} sx={{ margin: "auto", maxWidth: "50%" }}>
            <InputLabel sx={{ whiteSpace: "normal", paddingRight: "10px", margin: "auto" }}>
              {"Number of Pharma sponsors served to date"}
            </InputLabel>
          </Grid>
          <Grid item xs={5} sm={6} md={7} sx={{ padding: "10px" }}>
            <InputMaterial
              id={"PharmaSponsorsServed"}
              placeholder={""}
              label={""}
              name={`${name}.PharmaSponsorsServed`}
              type="number"
              value={form.values.step3.PharmaSponsorsServed}
              error={formErrors.step3?.errors?.PharmaSponsorsServed}
              touched={formErrors.step3?.touched?.PharmaSponsorsServed}
              sx={{ width: "112px" }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item p={"10px 25px 5px 25px"} xs={12}>
        <SelectField
          label={"Capabilities / Services Provided*"}
          placeholder={"Select all capabilities / services provided"}
          formName={name}
          options={
            sortedCapabilities.reduce(
              (result: Array<string>, current: { RFIAliasName: string; _id: string; RFIName: string; IsMandatory: boolean }) => {
                return current.RFIAliasName
                  ? [
                      ...result,
                      {
                        title: current.RFIAliasName,
                        value: current.RFIName,
                        id: current._id,
                        IsMandatory: current.IsMandatory,
                      },
                    ]
                  : result;
              },
              []
            )
          }
          selected={form.values.step3.ProviderCapabilities}
          id={"ProviderCapabilities"}
          error={formErrors.step3?.errors?.ProviderCapabilities}
          touched={formErrors.step3?.touched?.ProviderCapabilities}
        />
      </Grid>
     
      <Grid item p={"5px 25px 10px 25px"} xs={12}>
        <MultiValueInput
          label={"Other Capabilities / Services Provided"}
          placeholder={"Type and press `Enter` or `Tab`"}
          options={form.values.step3.OtherCapabilitiesOrServices}
          error={formErrors.errors?.step3?.OtherCapabilitiesOrServices}
          touched={formErrors.step3?.touched?.OtherCapabilitiesOrServices}
          id={"OtherCapabilitiesOrServices"}
          formName={name}
        />
      </Grid>
      <Grid item p={"10px 25px"} xs={12}>
        <SelectField
          label={"Therapeutic Areas Supported*"}
          type="multiple"
          formName={name}
          placeholder={"Select all therapeutic areas supported"}
          options={
            sortedTherapeuticAreas.reduce(
              (result: Array<string>, current: { TherapeuticAreaName: string; _id: string }) => {
                return current.TherapeuticAreaName
                  ? [
                      ...result,
                      {
                        title: current.TherapeuticAreaName,
                        value: current.TherapeuticAreaName,
                        id: current._id,
                      },
                    ]
                  : result;
              },
              []
            )
          }
          selected={form.values.step3.TherapeuticAreas}
          id={"TherapeuticAreas"}
          error={formErrors.step3?.errors?.TherapeuticAreas}
          touched={formErrors.step3?.touched?.TherapeuticAreas}
        />
      </Grid>
      <Grid item p={"5px 25px 10px 25px"} xs={12}>
        <SelectField
          label={"Indications"}
          type="multiple"
          formName={name}
          placeholder={"Select therapeutic areas first"}
          options={
            filteredIndications.length > 0
              ? filteredIndications
                  .sort((a, b) => a.Indication.localeCompare(b.Indication))
                  .map((indication: Indication) => ({
                    title: indication.Indication,
                    value: indication._id,
                    id: indication._id,
                  }))
              : []
          }
          dependantValue={form.values.step3.TherapeuticAreas}
          disabled={form.values.step3.TherapeuticAreas ? false : true}
          selected={form.values.step3.Indications}
          id={"Indications"}
          error={formErrors.step3?.errors?.Indications}
          touched={formErrors.step3?.touched?.Indications}
        />
      </Grid>
      <Grid item p={"5px 25px 10px 25px"} xs={12}>
        <MultiValueInput
          label={"Other Therapeutic/Indication"}
          placeholder={"Type and press `Enter` or `Tab`"}
          options={form.values.step3.OtherTherapeuticAreas}
          error={formErrors.errors?.step3?.OtherTherapeuticAreas}
          touched={formErrors.step3?.touched?.OtherTherapeuticAreas}
          id={"OtherTherapeuticAreas"}
          formName={name}
        />
      </Grid>
      <Grid item p={"10px 25px 5px 25px"} xs={12}>
        <SelectField
          id={"GeographicCoverage"}
          formName={name}
          label={"Geographic Regions Served*"}
          placeholder={"Select all regions served"}
          type="multiple"
          options={
            sortedGeographicRegions.reduce(
              (result: Array<string>, current: { GeographicRegionName: string }) => {
                return current.GeographicRegionName
                  ? [
                      ...result,
                      {
                        title: current.GeographicRegionName,
                        value: current.GeographicRegionName,
                      },
                    ]
                  : result;
              },
              []
            )
          }
          selected={form.values.step3.GeographicCoverage}
          error={formErrors.step3?.errors?.GeographicCoverage}
          touched={formErrors.step3?.touched?.GeographicCoverage}
        />
      </Grid>
     
      <Grid item p={"10px 25px 5px 25px"} xs={12}>
        <SelectField
          label={"Service Compliance Types"}
          placeholder={"Select all Service Compliance Types"}
          formName={name}
          options={
            sortedServiceCompliance.reduce(
              (result: Array<string>, current: { ServiceComplianceStatus: string; _id: string; Description: string }) => {
                return current.ServiceComplianceStatus
                  ? [
                      ...result,
                      {
                        title: current.Description,
                        value: current.ServiceComplianceStatus,
                        id: current._id,
                      },
                    ]
                  : result;
              },
              []
            )
          }
          selected={form.values.step3.ServiceComplianceStatuses}
          id={"ServiceComplianceStatuses"}
          error={formErrors.step3?.errors?.ServiceComplianceStatuses}
          touched={formErrors.step3?.touched?.ServiceComplianceStatuses}
        />
      </Grid>
    </Grid>
  );
}
