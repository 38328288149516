import React from 'react'
import { VisibilityOff, Visibility } from '@mui/icons-material'
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  TextField
} from '@mui/material'
import { FormikValues, useFormikContext } from 'formik'
import { materialInputStyles } from './InputMaterial'

interface InputPasswordProps {
  id: string
  label: string
  placeholder?: string
  type?: string
}
export default function PasswordField ({
  id,
  label,
  type = 'password',
  placeholder=""
}: InputPasswordProps) {
  const form = useFormikContext<FormikValues>() ;
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  })

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
    handleKeyDown()
  }

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setValues({
      ...values,
      showPassword: !values.showPassword
    })
    handleKeyDown()
  }

  const handleKeyDown = () => {
   setTimeout(() => {
    setValues({
      ...values,
      showPassword: false
    })
   }, 1000);
  }
  
  return (
    <div>
     <InputLabel
        sx={{ fontWeight: 700, color: form.touched[id] && form.errors[id] ? 'red' : '' }}
      >
        {label}
      </InputLabel>
      <FormControl variant='standard' fullWidth>
        <TextField
          fullWidth
          type={values.showPassword ? 'text' : 'password'}
          id={id}
          placeholder={placeholder}
          value={form.values[id]}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          helperText={form.touched[id] ? (form.errors[id] as string) : ''}
          error={form.touched[id] && Boolean(form.errors[id])}
          margin='dense'
          onKeyDown={handleKeyDown}
          // variant='outlined'
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge='end'
                  data-testid="togglePasswordVisibility"
                >
                  {values.showPassword ? <VisibilityOff sx={{color:'#8692A6'}} /> : <Visibility sx={{color:'#8692A6'}} />}
                </IconButton>
              </InputAdornment>
            )
          }}
          sx={materialInputStyles}
        />
      </FormControl>
    </div>
  )
}
