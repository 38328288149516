import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { buttonStyle } from "../../features/onbordingWizard/constant";
import { useDispatch, useSelector } from "react-redux";
import { getState } from "../../features/auth/authSlice";
import { FormikProps, useFormikContext } from "formik";
import { LocationType } from "../../features/onbordingWizard/OnboardingWizard";
import { useSubmitProviderWizardFormMutation } from "../../features/onbordingWizard/OnboardingWizardApiSlice";
import { setOnboardingWizardStatus } from "../../features/onbordingWizard/OnboardingWizardSlice";
import { useNavigate } from "react-router-dom";

type MyFormikValues = {
  IsMultiLocation: boolean;
  NumberOfMultiLocations: number;
  DoLocationsOfferSameCapabilities: boolean;
  QualityManagementSystemType: string;
  HasSubsidiaries: boolean;
  Locations: [];
};

const Template = () => {
  const { token } = useSelector(getState);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const form = useFormikContext<MyFormikValues>();
  const [submitWizardData] = useSubmitProviderWizardFormMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickSubmit = (form: FormikProps<MyFormikValues>) => {
    setSuccessMessage("");
    setErrorMessage("");
    setLoader(true);

    // Filter out invalid locations
    let dataToSend = {
      ...form.values,
      Locations: []
    };

    console.log("Submitting data:", dataToSend); // Debugging log

    submitWizardData(dataToSend)
      .unwrap()
      .then((payload) => {
        console.log("API Response:", payload); // Debugging log
        setSuccessMessage(payload.message);
        setTimeout(() => {
          setLoader(false);
          dispatch(setOnboardingWizardStatus(true));
          // navigate("/wizard/submit");
        }, 1000);
      })
      .catch((err) => {
        setLoader(false);
        if (err.data) {
          setErrorMessage(err.data.message);
        } else {
          setErrorMessage(err.error);
        }
        console.error("API Error:", err); // Debugging log
      });
  };

  const handleConfirm = () => {
    console.log("Confirm Clicked"); // Debugging log
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("status");

    // Ensure that form is valid before submission
    console.log("Form Values Before Submit:", form.values); // Debugging log

    onClickSubmit(form);

    window.location.replace(
      process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token
    );
  };

  const navigateWithEmail = ()=>{
    onClickSubmit(form);
  }

  return (
    <Box sx={{ minWidth: "500px", marginTop: "30vh", textAlign: "center" }}>
      <Box component={"div"} sx={{ textAlign: "center", margin: "10px" }}>
        <Typography variant='h5'>
        Congratulations!  You are now in the DQP Provider Community

        </Typography>
        <Typography variant='body1'>
          You may now access your provider dashboard within the Diligent Qualification Platform by navigating to{" "}
          <a href={process.env.REACT_APP_PLATFORM_REDIRECT_URL + "?token=" + token} onClick={navigateWithEmail}>
            platform.diligentpharma.com
          </a>{" "}
          or by clicking Get Started below. A Diligent Pharma representative will contact you shortly to discuss next steps. You may also access the Quick Reference Guides by clicking on Need Help.
        </Typography>
        <Typography variant='h5'>
          Welcome to the Diligent Qualification Platform!
        </Typography>

        <Button
          variant='contained'
          disabled={loader} // Disable button when loading
          onClick={handleConfirm}
          sx={{ ...buttonStyle }}
        >
          {"Get Started"}
        </Button>
      </Box>
    </Box>
  );
};

export default Template;
