import React, { useState } from "react";
import { Button, Modal, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
// import { Icon } from "@mui/material";
// import { FormIcon } from "./Icon";
import {Icon} from '@iconify/react'

interface DialogBoxProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  secondaryText?: string;
  primaryText?: string;
  secondaryAction?: () => void;
//   primaryAction: () => void;
  body: () => React.ReactNode;
  width?: string;
  isCloseEnable: boolean;
}

const colors = {
    primary: {
      main: "#130D4F",
      white: "#fff",
      dark: "#000"
    }
}


const DialogBox: React.FC<DialogBoxProps> = ({
  open,
  setOpen,
  secondaryText,
  secondaryAction,
  body,
  width = "auto",
  isCloseEnable,
}) => {

    console.log(isCloseEnable)
  return (
    <Modal isOpen={open} className="modal-dialog-centered" style={{ width }}>

      {isCloseEnable && (
         <Icon 
         onClick={() => setOpen(false)}
         icon={"basil:cross-outline"}
         width = "30px"
         height = "30px"
         style={{
             color: "#ffff",
             backgroundColor: colors.primary.main,
             position: "absolute",
             right: "-8px",
             top: "-8px",
             cursor: "pointer"
           }}
         />
      )}

      <div>{body()}</div>

      {/* <div className="modal-footer" style={{ display: "flex", justifyContent: "center", marginBottom: "2rem" }}>
        {secondaryText && (
          <Button
            className="new-event--add"
            color="primary"
            outline
            type="button"
            style={{ width: "8rem" }}
            onClick={() => {
              if (secondaryAction) {
                secondaryAction();
              } else {
                setOpen(false);
              }
            }}
          >
            {secondaryText}
          </Button>
        )}
     
      </div> */}
    </Modal>
  );
};
 
export default DialogBox;
