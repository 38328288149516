import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return {
      login: builder.mutation({
        query: (credentials) => ({
          url: "auth/login",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      logout: builder.mutation({
        query: (credentials) => ({
          url: "auth/logout",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      register: builder.mutation({
        query: (credentials) => ({
          url: "auth/register",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      verify: builder.mutation({
        query: (credentials) => ({
          url: "auth/verifyaccount",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      regenerateVerification: builder.mutation({
        query: (credentials) => ({
          url: "auth/resendverifylink",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      forgotPassord: builder.mutation({
        query: (credentials) => ({
          url: "auth/forgotpassword",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      resetPassord: builder.mutation({
        query: (credentials) => ({
          url: "auth/resetpassword",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      validateInvite: builder.query<any,{token: string }>({
        query: (credentials) => ({
          url: "provider/invite",
          method: "GET",
          mode: "cors",
          headers: { Authorization: "Bearer "+credentials.token }
        })
      }),
    };
  },
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useVerifyMutation,
  useRegenerateVerificationMutation,
  useForgotPassordMutation,
  useResetPassordMutation,
  useValidateInviteQuery
} = authApiSlice;
