import { apiSlice } from "../../app/api/apiSlice";

export const onboardingWizardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => {
    return {
      getProviderWizardData: builder.query<any,{}>({
        query: (credentials) => ({
          url: "onboardingwizard",
          method: "GET",
          mode: "cors",
        })
      }),
      saveWizard: builder.mutation({
        query: (credentials) => ({
          url: "onboardingwizard/save",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
      submitProviderWizardForm: builder.mutation({
        query: (credentials) => ({
          url: "onboardingwizard/submit",
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ ...credentials }),
        }),
      }),
    };
  },
});

export const {
  useGetProviderWizardDataQuery,
  useSaveWizardMutation,
  useSubmitProviderWizardFormMutation
} = onboardingWizardApiSlice;
