import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useFormikContext } from "formik";
import { FormHelperText } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const materialSelectStyles = {
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".MuiInputBase-root": {
    height: "3.2rem",
  },
  ".MuiTextField-root": {
    marginTop: "8px",
  },
  ".Mui-error.MuiSelect-select": {
    border: "1px solid #ff1744",
  },
  ".MuiSelect-select": {
    // background: "rgba(252, 251, 254, 0.8)",
    // border: "1px solid #C0C5D1",
    borderRadius: "6px",
    marginTop: "8px",
    // height: '3.2rem'
  },
  ".Mui-focused.MuiSelect-select": {
    border: "1px solid #1565D8",
    boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
  },
  "& .MuiOutlinedInput-input": {
    padding: "13.5px 14px",
  },
  "& .MuiSelect-icon": {
    top: "calc(50% - 0.3em)",
  },
};

function getStyles(name: string, personName: string, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export interface SelectMaterialProps {
  id: string;
  label: string;
  placeholder?: string;
  type?: "single" | "multiple";
  formName?: string;
  options: Array<string>;
  selected: Array<string>;
  error: string | undefined;
  touched: any;
  disabled?: boolean;
}
export interface FieldType {
  [key: string]: string[];
}

export default function SingleSelect({
  id,
  label,
  placeholder,
  type = "single",
  formName,
  options,
  selected = [],
  error,
  touched,
  disabled,
}: SelectMaterialProps) {
  const theme = useTheme();
  const form = useFormikContext<FieldType>();
  const [picked, setPicked] = React.useState<string>(
    selected.length ? selected[0] : ""
  );
  const name = `${formName}.${id}`;
  const handleChange = (event: SelectChangeEvent<typeof picked>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    // const newValue = typeof value === "string" ? value.split(",") : value;
    setPicked(value);
    form.setFieldValue(name, type === "single" ? value : [value]);
  };

  return (
    <div>
      <InputLabel
        id={`select${id}`}
        sx={{ fontWeight: 700, color: touched && Boolean(error) ? "red" : "" }}
      >
        {label}
      </InputLabel>
      <FormControl fullWidth variant='outlined'>
        <Select
          variant='outlined'
          labelId={`select${id}`}
          sx={{
            ...materialSelectStyles,
            "& .MuiSelect-select": {
              border: touched && error ? "1px solid red" : "1px solid #C0C5D1",
              background: disabled ? "#e8e9ed" : "rgba(252, 251, 254, 0.8)",
            },
            "& .MuiSelect-select:hover": {
              border:  disabled ? "1px solid #C0C5D1" : "1px solid #1565D8",
              boxShadow:  disabled ? "none" : "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
            },
          }}
          id={id}
          name={id}
          disabled={disabled}
          value={picked}
          onChange={handleChange}
          onBlur={form.handleBlur}
          // helperText={form.touched[id] ? (form.errors[id] as string) : ''}
          error={form.touched[id] && Boolean(form.errors[id])}
          placeholder={placeholder}
          input={<OutlinedInput id={id} label='' />}
          renderValue={(selected) =>
            selected.length ? (
              <Box sx={{ display: "flex", gap: 0.2 }}>{selected}</Box>
            ) : (
              <Box sx={{ color: "#999" }}>{placeholder}</Box>
            )
          }
          displayEmpty={true}
          MenuProps={MenuProps}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, picked, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        {touched && error ? (
          <FormHelperText sx={{ color: "red", marginLeft: 0 }}>
            {error}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
}
