import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

interface AppSnackBarProps {
  open: true | false;
  message: string;
  type?: "success" | "error" | "warning" | "info";
  time?: number;
  id?: number
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function AppSnackBar({
  open=false,
  message="",
  type = "info",
  time = 2000,
  id = Math.random()
}: AppSnackBarProps) {
  const [alertOpen, setAlertalertOpen] = React.useState(open);
  const [msg, setMsg] = React.useState("");
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    
    setAlertalertOpen(false);
  };

  useEffect(() => {
    console.log(message)
    
    if (message !== "") {
      setMsg(message);
      setAlertalertOpen(true);
    } else {
      setMsg("");
      setAlertalertOpen(false);
    }
  }, [message])
  

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {msg && <Snackbar
        key={Math.random()}
        // disableWindowBlurListener
        open={alertOpen}
        autoHideDuration={time}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {msg}
        </Alert>
      </Snackbar>}
    </Stack>
  );
}
