import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getState } from "./authSlice";

const RequireOnboarded = () => {
  const {user,token} = useSelector(getState)
  const location = useLocation();

  if (!token) {
    return <Navigate to='/signin' state={{ from: location }} replace />
  }
  return user === "Wizard" ? (
    <Outlet />
  ) : (
    <Navigate to='/onboarding' state={{ from: location }} replace />
  );
};
export default RequireOnboarded;
