import { Box, Typography } from "@mui/material";
import React from "react";
import { headerStyle, subHeaderStyle } from "./constant";

type StepHeaderProps = {
  title: string;
  subTitle: string;
};
export default function StepHeader({ title, subTitle }: StepHeaderProps) {
  // const theme = useTheme();
  // const isTabScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Box sx={{ margin: "auto", maxWidth: "520px" }}>
      <Typography variant='h3' sx={headerStyle}>
        {title}
      </Typography>
      <Typography variant='h4' sx={subHeaderStyle}>
        {subTitle}
      </Typography>
    </Box>
  );
}
