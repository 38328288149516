import React from "react";
import { useFormikContext } from "formik";
import MaterialTable from "../reuseable/materialTable";
import { LocationType } from "../../features/onbordingWizard/OnboardingWizard";
import { Box, Button } from "@mui/material";
import { buttonStyle } from "../../features/onbordingWizard/constant";
import { useSelector } from "react-redux";
import { selectWizardIsPlatformUser } from "../../features/onbordingWizard/OnboardingWizardSlice";

interface LocationConfirmationType {
  currLocation: number;
  setCurrLocation: (value: string, pos: number) => void;
  Locations: Array<LocationType>;
}
export default function LocationConfirmation({
  currLocation,
  setCurrLocation,
  Locations,
}: LocationConfirmationType) {
  const form = useFormikContext();
  const {isPlatformUser} = useSelector(selectWizardIsPlatformUser);
  const setPrevPage = () => {
    setCurrLocation("/wizard/locations",Locations.length-1);
  };
  const setPrevPosition = (pos: number) => {
    setCurrLocation("/wizard/locations",pos);
  };

  return (
    <div>
      <MaterialTable
        columns={["Address1", "City", "State", "Country"]}
        rows={Locations}
        currLocation={currLocation}
        setCurrLocation={setPrevPosition}
      />
      <Box component={"div"} sx={{ textAlign: "end", paddingTop: "20px" }}>
        <Button
          variant='outlined'
          onClick={() => setPrevPage()}
          sx={{ ...buttonStyle }}
        >
          {"Back"}
        </Button>

        <Button
          variant='contained'
          onClick={()=>form.handleSubmit()}
          sx={{ ...buttonStyle }}
          disabled={Boolean(isPlatformUser)}
        >
          {"Submit To Platform"} 
        </Button>
      </Box>
    </div>
  );
}
